import { MenuItem, Stack } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledSelect,
    ICategory,
    IProductQuery,
    ISupplier,
    Options,
    OptionsToggle,
    useCategoriesList,
    useSelectedOrganisation,
    useSuppliersList,
} from '../../../shared';
import { checkDifferentFilterAndFormValues } from '../../../shared/utils/check-different-filter-and-form-values.util';

interface Props {
    filter: Record<string, any>;
    onChange: (filter: IProductQuery & { options: Options }) => void;
}

export const ProductsFilter: FC<Props> = ({ filter, onChange }) => {
    const { t } = useTranslation();
    const form = useForm<IProductQuery>({ mode: 'onChange' });
    const formValues = form.watch();

    const defaultOptions: Options = useMemo(() => {
        return {
            filter: { type: 'title', label: t('filter') },
            categoryId: { label: t('category'), active: false },
            supplierId: { label: t('supplier'), active: false },
        };
    }, [t]);

    const filterOptions: Options = useMemo(() => {
        return filter.options || defaultOptions;
    }, [defaultOptions, filter.options]);

    const setOptions = useCallback((options: Options) => onChange({ ...filter, options }), [filter, onChange]);

    const { organisation } = useSelectedOrganisation();
    const { data: categories } = useCategoriesList({ pageSize: 100 });
    const { data: suppliers } = useSuppliersList({ pageSize: 100, organisationId: organisation?.id });

    useEffect(() => {
        form.reset(filter);
    }, [filter, form]);

    useEffect(() => {
        const { categoryId, supplierId } = filterOptions;
        if (!categoryId.active) form.setValue('categoryId', undefined);
        if (!supplierId.active) form.setValue('supplierId', undefined);
    }, [form, filterOptions]);

    useEffect(() => {
        if (!!Object.keys(formValues).length && checkDifferentFilterAndFormValues(formValues, filter)) {
            onChange({ ...formValues, options: filterOptions });
        }
    }, [formValues, filter, onChange, filterOptions]);

    return (
        <FormProvider {...form}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <OptionsToggle options={filterOptions} onChange={setOptions} />
                {filterOptions.categoryId.active && (
                    <ControlledSelect name="categoryId" className="filter" label={t('category')}>
                        <MenuItem value="">{t('all')}</MenuItem>
                        {(categories?.data || []).map((category: ICategory) => (
                            <MenuItem value={category.id} key={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}

                {filterOptions.supplierId.active && (
                    <ControlledSelect name="supplierId" className="filter" label={t('supplier')}>
                        <MenuItem value="">{t('all')}</MenuItem>
                        {(suppliers?.data || []).map((supplier: ISupplier) => (
                            <MenuItem value={supplier.id} key={supplier.id}>
                                {supplier.name}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}
            </Stack>
        </FormProvider>
    );
};
