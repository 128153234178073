import { Button, Stack } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from '../../../shared';

interface Props {
    paymentMethod: (arg: PaymentMethod) => void;
}

export const PaymentMethods: FC<Props> = ({ paymentMethod }) => {
    const { t } = useTranslation();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(PaymentMethod.CASH);

    useEffect(() => {
        if (selectedPaymentMethod) paymentMethod(selectedPaymentMethod);
    }, [paymentMethod, selectedPaymentMethod]);

    return (
        <Stack direction="row" spacing={2} sx={{ button: { px: 5 }, py: 1 }}>
            <Button
                variant="contained"
                onClick={() => setSelectedPaymentMethod(PaymentMethod.CASH)}
                sx={{
                    backgroundColor: selectedPaymentMethod === PaymentMethod.CASH ? 'primary' : 'grey',
                }}
            >
                {t('cash')}
            </Button>
            <Button
                variant="contained"
                onClick={() => setSelectedPaymentMethod(PaymentMethod.DIGITAL)}
                sx={{
                    backgroundColor: selectedPaymentMethod === PaymentMethod.DIGITAL ? 'primary' : 'grey',
                }}
            >
                {t('digital')}
            </Button>
            <Button
                variant="contained"
                onClick={() => setSelectedPaymentMethod(PaymentMethod.INVOICE)}
                sx={{
                    backgroundColor: selectedPaymentMethod === PaymentMethod.INVOICE ? 'primary' : 'grey',
                }}
            >
                {t('invoice')}
            </Button>
        </Stack>
    );
};
