import { Search } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    formatAmount,
    formatProductPrice,
    ICategory,
    ISupplier,
    Page,
    PermissionKeys,
    RowActions,
    SearchInput,
    SortOrder,
    TableComponent,
    useDeleteProduct,
    useHasPermission,
    useProductsList,
    useQueryParamsContext,
    useSelectedOrganisation,
} from '../../../shared';
import { ProductsFilter } from '../../components';
import { DownloadLabels } from '../../components/download-labels/download-labels.component';

export const ProductsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organisation } = useSelectedOrganisation();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.PRODUCTS_WRITE);

    const {
        queryParams: { page, pageSize, sort, search, filter },
        debouncedQueryParams,
        setFilter,
        setPage,
        setPageSize,
        setSort,
        setSearch,
    } = useQueryParamsContext('products', {
        sort: [{ field: 'name', sort: 'asc' }],
    });

    const { mutateAsync: deleteProduct } = useDeleteProduct();
    const { data: products, isPending } = useProductsList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
        organisationId: organisation?.id,
        categoryId: filter.categoryId || undefined,
        supplierId: filter.supplierId || undefined,
        tax: filter.tax || undefined,
        search: debouncedQueryParams.search,
    });

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('productName'),
            minWidth: 150,
            flex: 1,
            sortable: true,
        },
        {
            field: 'category',
            headerName: t('category'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: ICategory) => value?.name,
        },
        {
            field: 'barcodes',
            headerName: t('barcodes'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: string[]) => value?.map((code: string) => code).join(', '),
        },
        {
            field: 'price',
            headerName: t('productPrice'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: number, row) => value && formatProductPrice(row.measurementUnit, value, t),
        },
        {
            field: 'stockCount',
            headerName: t('stockCount'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: number, row) => formatAmount(row.measurementUnit, value || 0, t),
        },
        {
            field: 'stockTarget',
            headerName: t('minStockTarget'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: number, row) => formatAmount(row.measurementUnit, value || 0, t),
        },
        {
            field: 'supplier',
            headerName: t('supplier'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: ISupplier) => value?.name,
        },
        {
            field: ' ',
            headerName: '',
            width: 250,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <>
                    <DownloadLabels productId={row?.id} type="price" variant="icon" />
                    <DownloadLabels productId={row?.id} type="barcode" variant="icon" />
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            navigate(`${row?.id}`);
                        }}
                    >
                        <Search />
                    </IconButton>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`${row?.id}/edit`)}
                            onDelete={() => deleteProduct(row.id)}
                            deleteWarningTitle={t('deleteProduct')}
                            deleteWarningText={t('deleteProductText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('products')}
            filter={<ProductsFilter filter={filter} onChange={setFilter} />}
            actions={[
                <SearchInput value={search} setValue={setSearch} />,
                <Button component={Link} to="new" color="primary" variant="contained">
                    {t('newProduct')}
                </Button>,
            ]}
        >
            <TableComponent
                rows={products?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={products?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
            />
        </Page>
    );
};
