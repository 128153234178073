import { Button, CircularProgress, Stack } from '@mui/material';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISale, PaymentMethod } from '../../../shared';
import { useCompleteCurrentSale } from '../../hooks';
import { ISavePayment } from '../../models';

interface Props {
    currentSale: ISale;
    handleClose: () => void;
    creditPayment?: ISavePayment | null;
    hasCreditPayment: boolean;
    handlePrevious: () => void;
    isCashPayment: boolean;
    totalPrice: number;
    receivedAmount: string;
    returnAmount: number;
    selectedPaymentMethod: PaymentMethod;
}

export const NonCreditPaymentActions: FC<Props> = ({
    currentSale,
    handleClose,
    creditPayment,
    hasCreditPayment,
    handlePrevious,
    isCashPayment,
    totalPrice,
    receivedAmount,
    returnAmount,
    selectedPaymentMethod,
}) => {
    const { t } = useTranslation();
    const { mutateAsync: completeCurrentSale, isPending } = useCompleteCurrentSale();

    const disableConfirm = useMemo(
        () => isPending || (parseFloat(totalPrice.toFixed(2)) > parseFloat(receivedAmount) && isCashPayment),
        [isPending, totalPrice, receivedAmount, isCashPayment],
    );

    const onSubmit = useCallback(async () => {
        if (currentSale) {
            await completeCurrentSale({
                saleId: currentSale.id,
                completeSale: {
                    payments: [
                        ...(creditPayment ? [creditPayment] : []),
                        {
                            method: selectedPaymentMethod,
                            paidAmount: totalPrice,
                            receivedAmount: isCashPayment ? parseFloat(receivedAmount) : totalPrice,
                            returnedAmount: isCashPayment ? returnAmount : 0,
                        },
                    ],
                },
            });
        }
    }, [
        creditPayment,
        currentSale,
        completeCurrentSale,
        totalPrice,
        selectedPaymentMethod,
        receivedAmount,
        isCashPayment,
        returnAmount,
    ]);

    return (
        <Stack direction="row" mt={3} spacing={2}>
            {hasCreditPayment ? (
                <Button variant="outlined" onClick={handlePrevious} disabled={isPending} fullWidth>
                    {t('previous')}
                </Button>
            ) : (
                <Button variant="outlined" onClick={handleClose} disabled={isPending} fullWidth>
                    {t('cancel')}
                </Button>
            )}
            <Button
                variant="contained"
                disabled={disableConfirm}
                onClick={onSubmit}
                startIcon={isPending && <CircularProgress color="inherit" size={25} />}
                fullWidth
            >
                {t('confirm')}
            </Button>
        </Stack>
    );
};
