import { Dialog, DialogContent } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, ISale, QueryKeys, SaleStatus, useSelectedOrganisation } from '../../../shared';
import { ISavePayment } from '../../models';
import { CreditPayment } from '../credit-payment/credit-payment.component';
import { PaymentSuccessful } from '../payment-successful/payment-successful.component';
import { CustomerType } from '../../../customer/enums/customer-type.enum';
import { ISupportCaseInfo } from '../../../customer/models/support-case.model';
import { ICustomerInfo } from '../../../customer/models/customer.model';
import { NonCreditPayment } from '../non-credit-payment/non-credit-payment.component';

interface Props {
    open: boolean;
    onClose: () => void;
    currentSale: ISale;
    customerInfo?: ICustomerInfo;
    onCompleteSale: () => void;
}

export const CompleteCurrentSale: FC<Props> = ({ open, onClose, currentSale, customerInfo, onCompleteSale }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const [creditPayment, setCreditPayment] = useState<ISavePayment | null>();
    const [paymentScreen, setPaymentScreen] = useState<'credit' | 'non_credit'>();

    const supportCase = customerInfo?.type === CustomerType.SOCIAL ? (customerInfo as ISupportCaseInfo) : undefined;

    const creditSupportTypesInfo = useMemo(
        () =>
            supportCase?.activeSupportPeriods
                .flatMap(({ supportTypesInfo, referralOrganisation, organisationId }) =>
                    supportTypesInfo.map((info) => ({
                        ...info,
                        referralOrganisationName: referralOrganisation?.name,
                        organisationId,
                    })),
                )
                .filter(
                    ({ credit, spendCredit, organisationId }) =>
                        organisationId === organisation?.id && (credit || 0) > (spendCredit || 0),
                ) || [],
        [supportCase, organisation],
    );

    useEffect(() => {
        if (creditSupportTypesInfo.length) setPaymentScreen('credit');
        else setPaymentScreen('non_credit');
    }, [creditSupportTypesInfo]);

    const onHandleSetCredit = (creditPayment: ISavePayment | null) => {
        setCreditPayment(creditPayment);
        setPaymentScreen('non_credit');
    };

    const queryClient = useQueryClient();
    const onClosePaymentSuccessful = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.CURRENT_SALE, currentSale.organisationId] });
        onCompleteSale();
        onClose();
    }, [queryClient, onCompleteSale, onClose, currentSale]);

    return (
        <>
            <Dialog open={open && currentSale.status === SaleStatus.CREATED} fullWidth maxWidth="lg" onClose={onClose}>
                <DialogTitleWithClose onClose={onClose}>{t('checkoutPayment')}</DialogTitleWithClose>
                <DialogContent>
                    {paymentScreen === 'credit' && (
                        <CreditPayment
                            creditPayment={creditPayment}
                            setCreditPayment={onHandleSetCredit}
                            handleCancel={onClose}
                            supportTypesInfo={creditSupportTypesInfo}
                            totalPrice={currentSale.totalPrice}
                        />
                    )}
                    {paymentScreen === 'non_credit' && (
                        <NonCreditPayment
                            creditPayment={creditPayment}
                            currentSale={currentSale}
                            onClose={onClose}
                            hasCreditPayment={creditSupportTypesInfo.length > 0}
                            handlePrevious={() => setPaymentScreen('credit')}
                        />
                    )}
                </DialogContent>
            </Dialog>

            <PaymentSuccessful
                currentSale={currentSale}
                open={open && currentSale.status === SaleStatus.COMPLETED}
                onClose={onClosePaymentSuccessful}
            />
        </>
    );
};
