import { Box, Typography } from '@mui/material';
import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice, ISale, LabelValue } from '../../../shared';
import { ISavePayment } from '../../models';
import { roundAmount } from '../../utils';

interface Props {
    currentSale: ISale;
    setTotalPrice: (amount: number) => void;
    isCashPayment?: boolean;
    isReturnPayment?: boolean;
    creditPayment?: ISavePayment | null;
}

export const TotalPrice: FC<Props> = ({
    currentSale,
    setTotalPrice,
    isCashPayment = false,
    isReturnPayment = false,
    creditPayment,
}) => {
    const { t } = useTranslation();

    const amount = useMemo(() => {
        if (isReturnPayment) {
            return isCashPayment ? roundAmount(currentSale.totalPrice) : currentSale.totalPrice;
        } else {
            if (creditPayment) {
                if (isCashPayment) {
                    return roundAmount(currentSale.totalPrice - creditPayment.paidAmount);
                } else {
                    return currentSale.totalPrice - creditPayment.paidAmount;
                }
            } else {
                if (isCashPayment) {
                    return roundAmount(currentSale.totalPrice);
                } else {
                    return currentSale.totalPrice;
                }
            }
        }
    }, [currentSale.totalPrice, isCashPayment, isReturnPayment, creditPayment]);

    useEffect(() => {
        setTotalPrice(amount);
    }, [amount, setTotalPrice]);

    return (
        <LabelValue
            label={t('total')}
            value={
                creditPayment && currentSale.totalPrice > 0 ? (
                    <Box width="fit-content">
                        <Typography pl="10px">{formatPrice(currentSale.totalPrice)}</Typography>
                        <Typography borderBottom={1}>
                            - {formatPrice(creditPayment.paidAmount)} {t('CREDIT')}
                        </Typography>
                        <Typography pl="10px">{formatPrice(amount)}</Typography>
                    </Box>
                ) : (
                    formatPrice(amount)
                )
            }
        />
    );
};
