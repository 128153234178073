import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSettings } from '../../../admin/hooks';
import { useSelectedOrganisation } from '../../../shared';
import { ICreditPaymentForm, ISavePayment } from '../../models';
import { validateCurrentAmount } from '../../utils';
import { useCreditSchema } from '../../validators';
import { InputNumpad } from '../input-numpad/input-numpad.component';
import { ISupportTypeInfo } from '../../../customer/models/support-type.model';
import { CreditPaymentDetails } from './credit-payment-details.component';
import { CreditPaymentActions } from './credit-payment-actions.component';

interface Props {
    supportTypesInfo: ISupportTypeInfo[];
    creditPayment?: ISavePayment | null;
    setCreditPayment: (payment: ISavePayment | null) => void;
    handleCancel: () => void;
    totalPrice: number;
}
export const CreditPayment: FC<Props> = ({
    creditPayment,
    setCreditPayment,
    handleCancel,
    supportTypesInfo,
    totalPrice,
}) => {
    const { organisation } = useSelectedOrganisation();
    const { data: settings } = useSettings(organisation?.id);
    const [amount, setAmount] = useState('');

    const form = useForm<ICreditPaymentForm>({ resolver: yupResolver(useCreditSchema(totalPrice)) });

    useEffect(() => {
        form.setValue('amount', Number(amount));
    }, [amount, form]);

    useEffect(() => {
        if (creditPayment) {
            form.reset({
                supportTypeInfo: supportTypesInfo.find(
                    (info) =>
                        info.supportTypeId === creditPayment.supportTypeId &&
                        info.supportPeriodId === creditPayment.supportPeriodId,
                ),
                amount: creditPayment.paidAmount,
            });
        } else {
            form.setValue('supportTypeInfo', supportTypesInfo[0]);
        }
    }, [form, supportTypesInfo, creditPayment]);

    return (
        <FormProvider {...form}>
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <CreditPaymentDetails supportTypesInfo={supportTypesInfo} totalPrice={totalPrice} />
                    </Grid>
                    <Grid item xs={6}>
                        <InputNumpad
                            value={amount}
                            onChange={setAmount}
                            disable={validateCurrentAmount(amount, undefined, true)}
                            showNumpad={settings?.showNumpad}
                        />
                    </Grid>
                </Grid>

                <CreditPaymentActions setCreditPayment={setCreditPayment} handleCancel={handleCancel} />
            </form>
        </FormProvider>
    );
};
