import { isAxiosError } from 'axios';
import { useCallback } from 'react';
import { ISaleItem, isBadRequest, isNotFound, useSelectedOrganisation, useShop } from '../../shared';
import { SaleError } from '../enums';
import { useCreateSaleItem, useCurrentSale, useUpdateSaleItem } from './sales.hook';

export function useOnScan() {
    const {
        selectedProduct,
        selectedSaleItem,
        setProductNotFound,
        setSelectedProduct,
        setSelectedSaleItem,
        setShowWarningNoCustomer,
        returnTabActive,
        customer,
        setCustomerCredentials,
    } = useShop();
    const { mutateAsync: createSaleItem } = useCreateSaleItem();
    const { mutateAsync: updateSaleItem } = useUpdateSaleItem();
    const { organisation } = useSelectedOrganisation();
    const { data: currentSale } = useCurrentSale(organisation?.id);

    const handleScanSupportCase = useCallback(
        (scanned: string) => {
            let code = scanned?.replace(/-/g, '');
            let isSocialCustomer = undefined;
            if (code.length > 8) {
                if (code.startsWith('SDVH')) {
                    code = code.replace('SDVH', '');
                    isSocialCustomer = true;
                } else if (code.startsWith('SHOP')) {
                    code = code.replace('SHOP', '');
                    isSocialCustomer = false;
                }
            }

            setShowWarningNoCustomer(false);
            const cardCode = [code?.slice(0, 4), code?.slice(4)].join('-');
            setCustomerCredentials({ cardCode, isSocialCustomer });
        },
        [setCustomerCredentials, setShowWarningNoCustomer],
    );

    const scanNewSaleItem = useCallback(
        async (scanned: string, parsedCode: string, organisationId: string) => {
            if (!customer) return;
            if (!returnTabActive) {
                const item = {
                    barcode: scanned,
                    organisationId: organisationId,
                    supportCaseId: customer?.supportCaseId,
                    customerId: customer?.customerId,
                    customerType: customer.type,
                    cardCode: customer?.cardCode,
                    amountOfPeople: customer?.amountOfPeople,
                };
                try {
                    await createSaleItem(item);
                } catch (e) {
                    if (isNotFound(e)) {
                        //scanned code is unknown? => setProductNotFound (used to show warning)
                        setProductNotFound(scanned);
                    } else if (
                        isAxiosError(e) &&
                        isBadRequest(e) &&
                        [SaleError.AMOUNT_REQUIRED, SaleError.PRICE_REQUIRED].includes(e.response?.data.type)
                    ) {
                        //product found but expecting weight or product has no price => setSelectedProduct
                        setSelectedProduct({
                            barcode: parsedCode,
                            measurementUnit: e.response?.data.product.measurementUnit,
                            price: e.response?.data?.product?.hasPrice
                                ? e.response?.data?.product?.price * 1000
                                : undefined,
                            hasPrice: e.response?.data?.product?.hasPrice,
                        });
                    } else throw e;
                }
            }
        },
        [createSaleItem, customer, returnTabActive, setProductNotFound, setSelectedProduct],
    );

    const updateSaleItemWeight = useCallback(
        async (scanned: string, prefix: string, selectedSaleItem: ISaleItem, amountOfPeople?: number) => {
            const saleItem = {
                saleItemId: selectedSaleItem.id,
                ...selectedSaleItem,
                amount: parseInt(scanned.replace(prefix, ''), 10),
                amountOfPeople: amountOfPeople || undefined,
            };

            await updateSaleItem({
                saleItemId: selectedSaleItem.id,
                saleItem,
            });
            setSelectedSaleItem(undefined);
        },
        [setSelectedSaleItem, updateSaleItem],
    );

    const createSaleItemWithWeight = useCallback(
        async (scanned: string, prefix: string, organisationId: string) => {
            if (!customer) return;
            if (selectedProduct && !selectedProduct?.hasPrice) {
                setSelectedProduct({ ...selectedProduct, amount: parseInt(scanned.replace(prefix, ''), 10) });
            } else {
                const item = {
                    ...selectedProduct,
                    organisationId: organisationId,
                    supportCaseId: customer?.supportCaseId,
                    customerId: customer?.customerId,
                    customerType: customer.type,
                    cardCode: customer?.cardCode,
                    amountOfPeople: customer?.amountOfPeople,
                    amount: parseInt(scanned.replace(prefix, ''), 10),
                    alteredProductPrice: selectedProduct?.hasPrice ? undefined : 0,
                };
                await createSaleItem(item);

                setSelectedProduct(undefined);
            }
        },
        [createSaleItem, customer, selectedProduct, setSelectedProduct],
    );

    return useCallback(
        async (scanned: string) => {
            // scanning with azerty keyboard keeps -, scanning with qwerty keyboard removes -
            const parsedCode = scanned.replace(/-/g, '');
            const prefix = window.carity.environment.prefixScanWeight.replace(/-/g, '');

            if (!organisation) return;
            if (!currentSale && !customer) {
                //No supportCase selected yet? => scanned code tried as customer card
                handleScanSupportCase(parsedCode);
            } else {
                //SupportCase found? => scan a product
                if (!selectedProduct && !selectedSaleItem) {
                    //No product/sale item selected yet? => create a new sale item
                    await scanNewSaleItem(parsedCode, parsedCode, organisation.id);
                } else {
                    if (selectedSaleItem) {
                        // Update weight from existing sale item
                        await updateSaleItemWeight(parsedCode, prefix, selectedSaleItem, customer?.amountOfPeople);
                    } else if (parsedCode.startsWith(prefix) && (selectedProduct?.id || selectedProduct?.barcode)) {
                        // Create sale item with weight
                        await createSaleItemWithWeight(parsedCode, prefix, organisation.id);
                    }
                }
            }
        },
        [
            organisation,
            customer,
            currentSale,
            handleScanSupportCase,
            selectedProduct,
            selectedSaleItem,
            scanNewSaleItem,
            updateSaleItemWeight,
            createSaleItemWithWeight,
        ],
    );
}
