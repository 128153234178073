import { InputAdornment, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledAutocomplete, ControlledNumberInput, formatPrice, LabelValue } from '../../../shared';
import { ICreditPaymentForm } from '../../models';
import { ISupportTypeInfo } from '../../../customer/models/support-type.model';

interface Props {
    supportTypesInfo: ISupportTypeInfo[];
    totalPrice: number;
}
export const CreditPaymentDetails: FC<Props> = ({ supportTypesInfo, totalPrice }) => {
    const { t } = useTranslation();

    const form = useFormContext<ICreditPaymentForm>();
    const watchSupportTypeInfo = form.watch('supportTypeInfo');
    const watchAmount = form.watch('amount');

    const availableCredit = formatPrice((watchSupportTypeInfo?.credit || 0) - (watchSupportTypeInfo?.spendCredit || 0));
    const remainingCredit = watchSupportTypeInfo?.credit
        ? formatPrice(
              Number((watchSupportTypeInfo.credit - (watchSupportTypeInfo?.spendCredit || 0)).toFixed(2)) -
                  Number(watchAmount),
          )
        : undefined;

    return (
        <Stack
            width="100%"
            spacing={2}
            sx={{
                mr: 1,
                '.MuiTypography-root, .MuiFormLabel-root': { fontSize: '14px', fontWeight: 'bold', mb: 0 },
                '.MuiBox-root, .MuiInputAdornment-root p, input': { fontSize: '18px', fontWeight: 'normal' },
            }}
        >
            <ControlledAutocomplete
                name="supportTypeInfo"
                defaultValue={null}
                options={supportTypesInfo}
                getOptionLabel={(value) =>
                    `${value.supportType?.name} ${formatPrice((value?.credit || 0) - (value?.spendCredit || 0))} (${t(value.frequency as string)})`
                }
                isOptionEqualToValue={(option, value) =>
                    option.supportTypeId === value.supportTypeId && option.supportPeriodId === value.supportPeriodId
                }
            />

            <LabelValue label={t('totalPrice')} value={formatPrice(totalPrice)} />

            <LabelValue label={t('availableCredit')} value={availableCredit} />

            <ControlledNumberInput
                name="amount"
                label={t('paidCredit')}
                slotProps={{
                    input: { startAdornment: <InputAdornment position="start" children={'€'} /> },
                    htmlInput: { readOnly: true },
                }}
                sx={{ '.Mui-focused': { color: 'inherit' } }}
                decimalScale={2}
                focused
            />

            <LabelValue label={t('remainingCredit')} value={remainingCredit} />
        </Stack>
    );
};
