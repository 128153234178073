import React, { FC } from 'react';
import { formatPrice, IPayment } from '../../../shared';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface Props {
    payments: IPayment[] | void;
}

export const SaleDetailPayments: FC<Props> = ({ payments }) => {
    const { t } = useTranslation();

    return (
        <TableContainer sx={{ maxHeight: '300px' }}>
            <Table stickyHeader sx={{ th: { backgroundColor: '#FFF', borderBottomWidth: '2px' } }}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('paymentMethod')}</TableCell>
                        <TableCell>{t('receivedAmount')}</TableCell>
                        <TableCell>{t('returnedAmount')}</TableCell>
                        <TableCell>{t('paidAmount')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{ maxHeight: '200px' }}>
                    {payments?.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{t(item.method.toLowerCase())}</TableCell>
                            <TableCell>{formatPrice(item.receivedAmount)}</TableCell>
                            <TableCell>{formatPrice(item.returnedAmount)}</TableCell>
                            <TableCell>{formatPrice(item.paidAmount)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
