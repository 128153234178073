import { InputAdornment } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MeasurementUnit } from '../../enums';
import { ControlledCheckbox } from '../controlled-checkbox/controlled-checkbox.component';
import { ControlledNumberInput } from '../controlled-number-input/controlled-number-input.component';
import { FormGrid } from '../form-grid/form-grid.component';
import { Section } from '../section/section.component';

interface Props {
    prefixName: string;
}

export const ProductPricingInputs: FC<Props> = ({ prefixName }) => {
    const { t } = useTranslation();
    const form = useFormContext();
    const watchHasPrice = form.watch(`${prefixName}hasPrice`, true);
    const watchPrice = form.watch(`${prefixName}price`);
    const watchSolidarityPrice = form.watch(`${prefixName}solidarityPrice`);
    const watchMeasurementUnit = form.watch(`${prefixName}measurementUnit`);

    useEffect(() => {
        if (!watchHasPrice && (watchPrice !== undefined || watchSolidarityPrice !== undefined)) {
            form.setValue(`${prefixName}price`, undefined);
            form.setValue(`${prefixName}solidarityPrice`, undefined);
        }
    }, [form, watchHasPrice, prefixName, watchPrice, watchSolidarityPrice]);

    return (
        <Section title={t('pricing')}>
            <FormGrid sm={12} md={6} sx={{ '.MuiTextField-root': { mb: 1 } }}>
                <ControlledNumberInput
                    sx={{ mb: 3 }}
                    name={`${prefixName}price`}
                    label={watchMeasurementUnit === MeasurementUnit.GRAM ? t('productPriceKg') : t('productPrice')}
                    required={watchHasPrice}
                    disabled={!watchHasPrice}
                    slotProps={{
                        input: {
                            startAdornment: <InputAdornment position="start" children={'€'} />,
                        },
                    }}
                />
                <ControlledNumberInput
                    sx={{ mb: 1 }}
                    name={`${prefixName}solidarityPrice`}
                    label={
                        watchMeasurementUnit === MeasurementUnit.GRAM
                            ? t('solidarityProductPriceKg')
                            : t('solidarityProductPrice')
                    }
                    required={watchHasPrice}
                    disabled={!watchHasPrice}
                    slotProps={{
                        input: {
                            startAdornment: <InputAdornment position="start" children={'€'} />,
                        },
                    }}
                />
            </FormGrid>
            <ControlledCheckbox
                control={form.control}
                name={`${prefixName}hasPrice`}
                label={t('hasPrice')}
                sx={{ ml: 2, maxHeight: 20 }}
            />
            <ControlledCheckbox
                control={form.control}
                name={`${prefixName}excludeFromBudget`}
                label={t('excludeFromBudgetText')}
                sx={{ ml: 2 }}
            />
        </Section>
    );
};
