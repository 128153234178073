import { Grid } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { ISale, useBreakpoint, useSelectedOrganisation, Widget } from '../../../shared';
import { SupportCaseInfoWarningPanel } from './support-case-info-warning-panel/support-case-info-warning-panel.component';
import { SupportCaseInfoData } from './support-case-info-data/support-case-info-data.component';
import { SupportPeriodsInfo } from './support-periods-info/support-periods-info.component';
import { ISupportCaseInfo } from '../../models/support-case.model';

interface Props {
    currentSale?: ISale;
    supportCase: ISupportCaseInfo;
}

export const SupportCaseInfo: FC<Props> = ({ currentSale, supportCase }) => {
    const lg = useBreakpoint('lg');
    const { showWarningPanel } = window.carity.environment;
    const { organisation } = useSelectedOrganisation();

    const activeSupportPeriod = useMemo(
        () =>
            supportCase?.activeSupportPeriods
                .filter((period) =>
                    period.supportTypesInfo.some(
                        (type) => type.supportTypeId === window.carity.environment.supportTypeId,
                    ),
                )
                .sort((a, b) => {
                    if (a.organisationId === b.organisationId) return b.endDate.localeCompare(a.endDate);
                    if (b.organisationId === organisation?.id) return 1;
                    if (a.organisationId === organisation?.id) return -1;
                    else return b.endDate.localeCompare(a.endDate);
                })[0],
        [supportCase, organisation],
    );

    return (
        <Grid container spacing={2} flexDirection={lg ? 'row' : 'column-reverse'}>
            <Grid item md={12} lg={activeSupportPeriod?.endDate || showWarningPanel ? (showWarningPanel ? 8 : 9) : 12}>
                <SupportCaseInfoData
                    supportCase={supportCase}
                    currentSale={currentSale}
                    activeSupportPeriod={activeSupportPeriod}
                />
            </Grid>

            {showWarningPanel ? (
                <Grid item md={12} lg={4}>
                    <SupportCaseInfoWarningPanel supportCase={supportCase} activeSupportPeriod={activeSupportPeriod} />
                </Grid>
            ) : (
                <Grid item md={12} lg={3}>
                    {activeSupportPeriod?.endDate && (
                        <Widget sx={{ minHeight: 'fit-content', mb: 0 }}>
                            <SupportPeriodsInfo activeSupportPeriod={activeSupportPeriod} organisation={organisation} />
                        </Widget>
                    )}
                </Grid>
            )}
        </Grid>
    );
};
