import { Box, Button, Dialog, Tab, Tabs } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../admin/hooks';
import {
    isValueDefined,
    MeasurementUnit,
    PermissionKeys,
    SelectedProductType,
    useHasPermission,
    useSelectedOrganisation,
    useShop,
} from '../../../shared';
import { useCurrentSale } from '../../hooks';
import { getProductPrice } from '../../utils/product-price.util';
import { SaveProductAmount } from '../save-product-amount/save-product-amount.component';
import { SaveProductPrice } from '../save-product-price/save-product-price.component';

interface Props {
    open: boolean;
    onClose: () => void;
    onSubmit: (number: number, price: number) => void;
    currentAmount?: number;
    isNewSaleItem?: boolean;
    product?: SelectedProductType;
}

export const SaveProduct: FC<Props> = ({ open, onClose, onSubmit, currentAmount, isNewSaleItem = true, product }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { data: currentSale } = useCurrentSale(organisation?.id);
    const { data: settings } = useSettings(organisation?.id);
    const { customer } = useShop();

    const [amount, setAmount] = useState<string>('');
    const [unitPrice, setUnitPrice] = useState<string>('');
    const [totalPrice, setTotalPrice] = useState<string>('');
    const [selectedTab, setSelectedTab] = useState<string>('amount');
    const isGram = product?.measurementUnit === MeasurementUnit.GRAM;

    const { hasPermission } = useHasPermission();
    const hasAlterProductPricePermission = hasPermission(PermissionKeys.ALTER_PRODUCT_PRICE);

    useEffect(() => {
        const price = !isNewSaleItem
            ? isValueDefined(product?.price)
                ? product?.price || 0
                : ''
            : isValueDefined(getProductPrice(customer, product))
              ? getProductPrice(customer, product) || 0
              : '';

        if (currentAmount) {
            if (isGram) {
                setAmount((currentAmount / 1000).toString());
                setUnitPrice(price.toString());
                setTotalPrice(price || price === 0 ? ((price * currentAmount) / 1000).toString() : '');
            } else {
                setAmount(currentAmount.toString());
                setUnitPrice(price.toString());
                setTotalPrice(price || price === 0 ? (price * currentAmount).toString() : '');
            }
        } else {
            if (isGram) {
                setAmount('0');
                setUnitPrice(price?.toString());
                setTotalPrice(price ? '0' : '');
            } else {
                setAmount('1');
                setUnitPrice(price?.toString());
                setTotalPrice(price?.toString());
                setSelectedTab(price !== '' ? 'amount' : 'price');
            }
        }
    }, [currentAmount, isGram, setAmount, product, customer, currentSale, isNewSaleItem]);

    const handleClose = useCallback(() => {
        onClose();
        setSelectedTab('amount');
    }, [onClose, setSelectedTab]);

    const handleSubmit = useCallback(() => {
        if (amount) onSubmit(parseFloat(amount), parseFloat(unitPrice));
        handleClose();
    }, [amount, unitPrice, onSubmit, handleClose]);

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                event.preventDefault();
                handleClose();
            }
            if (event.key === 'Enter') {
                event.preventDefault();
                handleSubmit();
            }
        };
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [handleClose, handleSubmit]);

    return (
        <Dialog open={open} onClose={handleClose}>
            {(hasAlterProductPricePermission || !product?.price) && (
                <Tabs variant="fullWidth" value={selectedTab} onChange={(e, value) => setSelectedTab(value)}>
                    <Tab label={t('amount')} value={'amount'} key={'amount'} />
                    <Tab label={t('price')} value={'price'} key={'price'} />
                </Tabs>
            )}

            <Box p={2} sx={{ justifyContent: 'center' }}>
                {selectedTab === 'amount' ? (
                    <SaveProductAmount
                        amount={amount}
                        setAmount={setAmount}
                        unitPrice={unitPrice}
                        setTotalPrice={setTotalPrice}
                        measurementUnit={product?.measurementUnit}
                        showNumpad={settings?.showNumpad || false}
                    />
                ) : (
                    <SaveProductPrice
                        unitPrice={unitPrice}
                        setUnitPrice={setUnitPrice}
                        totalPrice={totalPrice}
                        setTotalPrice={setTotalPrice}
                        amount={amount}
                        showNumpad={settings?.showNumpad || false}
                    />
                )}

                <Button
                    sx={{ minWidth: '100%', fontSize: '14px', mt: 2 }}
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!amount || amount === '0' || unitPrice === ''}
                >
                    {isNewSaleItem ? t('addAmount') : t('editAmount')}
                </Button>
            </Box>
        </Dialog>
    );
};
