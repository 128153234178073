import { Grid, Stack } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../admin/hooks';
import { formatPrice, ISale, LabelValue, PaymentMethod, useSelectedOrganisation } from '../../../shared';
import { ISavePayment } from '../../models';
import { roundReturnAmount, validateCurrentAmount } from '../../utils';
import { InputNumpad } from '../input-numpad/input-numpad.component';
import { PaymentMethods } from '../payment-methods/payment-methods.component';
import { ReceivedAmount } from '../received-amount/received-amount.component';
import { TotalPrice } from '../total-price/total-price.component';
import { NonCreditPaymentActions } from './non-credit-payment-actions.component';

interface Props {
    currentSale: ISale;
    onClose: () => void;
    creditPayment?: ISavePayment | null;
    hasCreditPayment: boolean;
    handlePrevious: () => void;
}

export const NonCreditPayment: FC<Props> = ({
    currentSale,
    onClose,
    creditPayment,
    hasCreditPayment,
    handlePrevious,
}) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { data: settings } = useSettings(organisation?.id);

    const [totalPrice, setTotalPrice] = useState<number>(currentSale.totalPrice);
    const [receivedAmount, setReceivedAmount] = useState<string>('0');
    const [returnAmount, setReturnAmount] = useState<number>(0);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(PaymentMethod.CASH);

    useEffect(() => {
        if (receivedAmount && currentSale.totalPrice > 0) {
            const difference = parseFloat((parseFloat(receivedAmount) - totalPrice).toFixed(2));
            if (selectedPaymentMethod !== PaymentMethod.CASH) {
                setReturnAmount(difference);
            } else {
                setReturnAmount(roundReturnAmount(difference));
            }
        } else {
            setReturnAmount(roundReturnAmount(-totalPrice));
        }
    }, [currentSale.totalPrice, receivedAmount, selectedPaymentMethod, totalPrice]);

    const isCashPayment = useMemo(() => {
        return selectedPaymentMethod === PaymentMethod.CASH;
    }, [selectedPaymentMethod]);

    const handleClose = useCallback(async () => {
        onClose();
        setReceivedAmount('0');
        setReturnAmount(0);
    }, [onClose]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Stack
                        spacing={2}
                        sx={{
                            '.MuiTypography-root, .MuiFormLabel-root': { fontSize: '14px', fontWeight: 'bold', mb: 0 },
                            '.MuiBox-root,.MuiBox-root .MuiTypography-root, .MuiInputAdornment-root p, input': {
                                fontSize: '18px',
                                fontWeight: 'normal',
                            },
                        }}
                    >
                        <PaymentMethods paymentMethod={(method) => setSelectedPaymentMethod(method)} />

                        <TotalPrice
                            creditPayment={creditPayment}
                            currentSale={currentSale}
                            isCashPayment={isCashPayment}
                            isReturnPayment={currentSale.totalPrice <= 0}
                            setTotalPrice={setTotalPrice}
                        />

                        {isCashPayment && totalPrice !== 0 && (
                            <>
                                {totalPrice > 0 && (
                                    <ReceivedAmount amount={receivedAmount} setAmount={setReceivedAmount} />
                                )}
                                <LabelValue label={t('returnAmount')} value={formatPrice(Math.max(returnAmount, 0))} />
                            </>
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    {isCashPayment && totalPrice > 0 && (
                        <InputNumpad
                            value={receivedAmount}
                            onChange={setReceivedAmount}
                            disable={validateCurrentAmount(receivedAmount, undefined, true)}
                            showNumpad={settings?.showNumpad}
                        />
                    )}
                </Grid>
            </Grid>

            <NonCreditPaymentActions
                creditPayment={creditPayment}
                currentSale={currentSale}
                handleClose={handleClose}
                hasCreditPayment={hasCreditPayment}
                handlePrevious={handlePrevious}
                isCashPayment={isCashPayment}
                totalPrice={totalPrice}
                receivedAmount={receivedAmount}
                returnAmount={returnAmount}
                selectedPaymentMethod={selectedPaymentMethod}
            />
        </>
    );
};
