export default {
    products: 'Producten',
    product: 'Product',
    downloadBarcodeLabel: 'Download barcodes',
    downloadPriceLabel: 'Download prijsetiket',
    downloadPriceLabels: 'Download prijsetiketten',
    productName: 'Productnaam',
    barcode: 'Barcode',
    barcodes: 'Barcodes',
    barcodesLabel: 'Druk enter voor het toevoegen van een barcode',
    pricing: 'Prijzen',
    productPrice: 'Verkoopprijs inclusief btw.',
    productPriceKg: 'Verkoopprijs per kg, inclusief btw.',
    solidarityProductPrice: 'Solidaire prijs inclusief btw.',
    solidarityProductPriceKg: 'Solidaire prijs per kg, inclusief btw.',
    lastPurchasePrice: 'Laatste aankoopprijs',
    hasPrice: 'Product heeft een prijs',
    productTax: 'BTW percentage',
    productTaxInfo:
        'Het BTW percentage wordt bepaald door de geselecteerde categorie. Je kan deze ook overschrijven door zelf een waarde te selecteren.',
    stockCount: 'Aantal in stock',
    outOfStock: 'Niet op voorraad',
    currentStock: 'Huidige stock',
    minStockTarget: 'Minimum stock',
    minExpectedStockTarget: 'Minimum aantal verwacht in stock',
    standardOrderAmount: 'Standaard bestel hoeveelheid',
    standardOrderAmountColli: 'Standaard bestel hoeveelheid in colli',
    amountPerColli: 'Aantal producten per colli',
    storeOrder: 'Winkel volgorde',
    standardSupplier: 'Standaard leverancier',
    articleNumberSupplier: 'Artikelnummer van standaard leverancier',
    productDetails: 'Product details',
    productDisplayDetails: 'Winkel weergave',
    productStockDetails: 'Stock details',
    excludeFromBudgetText: 'Product uitsluiten van de budgettelling',
    excludeFromBudget: 'Product uitsluiten',
    newProduct: 'Nieuw product',
    updateProduct: 'Wijzig product',
    noBarcodeTitle: 'Geen barcodes ingevuld',
    noBarcodeText: 'Bent u zeker dat u een product zonder barcode wilt aanmaken?',
    deleteProduct: 'Verwijder product',
    deleteProductText: 'Bent u zeker dat u dit product wilt verwijderen?',
    measurementUnit: 'Eenheid',
    measurementWeight: 'Gewicht',
    pricePerKg: '{{price}} €/kg',
    pricePerPiece: '{{price}} €/st',
    GRAM: 'Gram',
    PIECE: 'Stukartikel',
    createdNewCategory:
        'Er zijn nog geen categorien binnen deze organisatie, indien je categorien wil toevoegen aan dit product moet je deze eerst aanmaken, dat kan ',
    createdNewCategoryLink: 'hier',
    createdNewShopCategory:
        'Er zijn nog geen kassalijsten binnen deze organisatie, indien je kassalijst wil toevoegen aan dit product moet je deze eerst aanmaken, dat kan ',
    createdNewShopCategoryLink: 'hier',
    barcodesAlreadyExists: 'De ingevoerde barcode bestaat al voor aan ander product.',
    maxFileSize: 'Maximum bestand grootte:',
    imageForCheckoutSystem: 'Afbeelding voor kassasysteem',
    uploadImage: 'Afbeelding opladen',
    deleteImageTitle: 'Afbeelding verwijderen',
    deleteImageDescription: 'Ben je zeker dat je de afbeelding van dit product wilt verwijderen?',
    uploadFailed: {
        GENERIC_ERROR: 'Er ging iets mis bij het uploaden van het bestand.',
        FILE_TOO_BIG: 'Het bestand mag niet groter zijn dan 4MB. Probeer opnieuw met een kleiner bestand.',
        WRONG_FILE_TYPE: 'Het bestand heeft een type dat niet toegestaan is. De toegelaten types zijn JPEG en PNG.',
        FILE_TOO_BIG_AND_WRONG_FILE_TYPE:
            'Het bestand mag niet groter zijn dan 4MB en is niet van een toegelaten type. Probeer opnieuw met een kleiner bestand. De toegelaten types zijn JPEG en PNG.',
    },
    errorIncorrectValues: 'Enkele velden zijn incorrect ingevuld.',

    saleQuota: 'Verkoop quota',
    saleQuotum: 'Verkoop quotum',
    saleQuotumRules: 'Verkoop quotum regels',
    newSaleQuotum: 'Nieuw verkoop quotum',
    addSaleQuotum: 'Voeg een verkoop quotum toe',
    updateSaleQuotum: 'Wijzig verkoop quotum',
    amountOfPeople: 'Aantal personen',
    saleQuotaAmountKg: 'Maximale hoeveelheid (kg)',
    saleQuotaAmountPiece: 'Maximale hoeveelheid (stuks)',
    rules: 'Regels',
    addRule: 'Voeg een regel toe',
    deleteSaleQuotumTitle: 'Verwijder verkoop quotum',
    deleteSaleQuotumText: 'Bent u zeker dat u deze verkoop quotum wilt verwijderen?',
    saleQuotumProductConflict: 'Er bestaat al een quotum voor dit product',
    period: 'Periode',
    SALE: 'Verkoop',
    WEEKLY: 'Wekelijks',
    BIWEEKLY: 'Tweewekelijks',
    MONTHLY: 'Maandelijks',

    categories: 'Categorieën',
    category: 'Categorie',
    newCategory: 'Nieuw categorie',
    updateCategory: 'Wijzig categorie',
    deleteCategory: 'Verwijder categorie',
    deleteCategoryText: `Bent u zeker dat u deze categorie wilt verwijderen? Indien deze categorie is toegekend aan een product wordt deze ook verwijdert bij het product.`,

    shopCategories: 'Kassalijsten',
    shopCategory: 'Kassalijst',
    shopCategoryShow: 'Kassalijst tonen',
    newShopCategory: 'Nieuwe kassalijst',
    updateShopCategory: 'Wijzig kassalijst',
    deleteShopCategory: 'Verwijder kassalijst',
    deleteShopCategoryText: `Bent u zeker dat u deze kassalijst wilt verwijderen? Indien deze kassalijst is toegekend aan een product wordt deze ook verwijdert bij het product.`,

    suppliers: 'Leveranciers',
    supplier: 'Leverancier',
    supplierName: 'Leverancier naam',
    newSupplier: 'Nieuwe leverancier',
    updateSupplier: 'Wijzig leverancier',
    deleteSupplier: 'Verwijder leverancier',
    deleteSupplierText: `Bent u zeker dat u deze leverancier wilt verwijderen?`,
    discountPercentage: 'Kortingspercentage',
    discountMustBeBetween: 'Het kortingspercentage moet tussen 0 en 100 zijn',
    supplierTaxIncluded: 'BTW inclusief (standaard selectie bij een aankoop)',

    organisation: 'Organisatie',
    organisations: 'Organisaties',
    general: 'Algemeen',
    settings: 'Instellingen',
    parties: 'Partijen',
    saleTicketMessage: 'Bericht op het kassaticket',
    generalSettings: 'Algemene instellingen',
    solidaritySettings: 'Solidariteit instellingen',
    showSolidarityPriceOnLabel: 'Toon solidariteit prijs op het prijsetiket',
    solidarityPriceIsPurchasePrice: 'Solidariteit prijs is aankoopprijs',
    additionalSolidarityCharge: 'Toevoeging van solidariteit',
    showNumpadMessage: 'Numeriek toetsenbord weergeven',
    budgets: 'Budgetten',
    budget: 'Budget',
    addBudget: 'Voeg een budget toe',
};
