import { Button, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from '../../../shared';
import { ICreditPaymentForm, ISavePayment } from '../../models';

interface Props {
    setCreditPayment: (payment: ISavePayment | null) => void;
    handleCancel: () => void;
}
export const CreditPaymentActions: FC<Props> = ({ setCreditPayment, handleCancel }) => {
    const { t } = useTranslation();

    const form = useFormContext<ICreditPaymentForm>();

    const onHandlePayments = (credit: ICreditPaymentForm) => {
        setCreditPayment(
            credit.amount
                ? {
                      paidAmount: Number(credit.amount),
                      method: PaymentMethod.CREDIT,
                      receivedAmount: Number(credit.amount),
                      returnedAmount: 0,
                      supportTypeId: credit.supportTypeInfo.supportTypeId,
                      supportPeriodId: credit.supportTypeInfo.supportPeriodId,
                      referralOrganisationName: credit.supportTypeInfo.referralOrganisationName,
                  }
                : null,
        );
    };
    return (
        <Stack direction="row" mt={3} spacing={2}>
            <Button variant="outlined" onClick={handleCancel} fullWidth>
                {t('cancel')}
            </Button>
            <Button variant="contained" onClick={form.handleSubmit(onHandlePayments)} fullWidth>
                {t('next')}
            </Button>
        </Stack>
    );
};
