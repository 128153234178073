import { InputAdornment, TextField } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelValue, NumberInput } from '../../../shared';

interface Props {
    amount: string;
    setAmount: (amount: string) => void;
}

export const ReceivedAmount: FC<Props> = ({ amount, setAmount }) => {
    const { t } = useTranslation();

    return (
        <LabelValue
            label={t('paid')}
            value={
                <TextField
                    slotProps={{
                        input: {
                            inputComponent: NumberInput as any,
                            startAdornment: <InputAdornment position="start" children={'€'} />,
                        },
                        htmlInput: { decimalScale: 2, readOnly: true },
                    }}
                    value={amount}
                    onChange={(input) => setAmount(input.target.value)}
                    focused
                />
            }
        />
    );
};
