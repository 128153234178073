import { format } from 'date-fns';
import { isDateValid } from '../utils';

export function mapDateString(date: string | undefined | null | Date): string | null {
    if (date instanceof Date) {
        if (isDateValid(date)) return format(date, 'yyyy-MM-dd');
        return null;
    }
    return date || null;
}

export function mapDateStringToApiDate(date: string | undefined | null): string | undefined {
    try {
        return date ? format(new Date(date), 'yyyy-MM-dd') : undefined;
    } catch {
        return undefined;
    }
}
