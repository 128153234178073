import { ListAltTwoTone, Sell, ShoppingCartTwoTone } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    formatAmount,
    formatDateString,
    formatPrice,
    IParams,
    LabelValue,
    Page,
    TableComponent,
    useQueryParams,
    Widget,
} from '../../../shared';
import { usePurchase } from '../../hooks';

export const PurchaseDetailPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;
    const { page, setPage, pageSize, setPageSize } = useQueryParams();
    const { data: purchase } = usePurchase(id);

    const columns: GridColDef[] = [
        {
            field: 'product.name',
            headerName: t('name'),
            minWidth: 150,
            flex: 1,
            sortable: true,
            renderCell: ({ row }: GridCellParams) => row.product.name,
        },
        {
            field: 'unitPurchasePrice',
            headerName: t('unitPurchasePrice'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: number) => formatPrice(value),
        },
        {
            field: 'amount',
            headerName: t('amount'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: number, row) => formatAmount(row.product.measurementUnit, value, t),
        },
        {
            field: 'product.stockCount',
            headerName: t('currentStock'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            renderCell: ({ row }: GridCellParams) =>
                formatAmount(row.product.measurementUnit, row.product.stockCount, t),
        },
    ];

    return (
        <Page onBack={() => navigate('/stock/purchases')} title={t('purchaseDetail')} loading={!purchase}>
            {purchase && (
                <Stack spacing={2}>
                    <Widget title={t('invoiceData')} icon={<ListAltTwoTone color="primary" />}>
                        <LabelValue label={t('isGift')} value={purchase.gift ? t('yes') : t('no')} />
                        <LabelValue label={t('invoiceNumber')} value={purchase.invoiceNumber} />
                        <LabelValue label={t('date')} value={formatDateString(purchase.date.toString())} />
                        <LabelValue label={t('supplier')} value={purchase.supplier?.name} />
                    </Widget>
                    <Widget
                        title={t('products')}
                        icon={<ShoppingCartTwoTone color="primary" />}
                        hasTable
                        actions={
                            <Button
                                color="secondary"
                                variant="outlined"
                                size="small"
                                startIcon={<Sell />}
                                onClick={() => window.open(`/shop-api/purchases/${id}/prices`, '__blank')}
                            >
                                {t('downloadPriceLabels')}
                            </Button>
                        }
                    >
                        <TableComponent
                            rows={purchase?.purchaseItems || []}
                            columns={columns}
                            page={page}
                            pageSize={pageSize}
                            setPage={setPage}
                            setPageSize={setPageSize}
                            rowCount={purchase.purchaseItems.length || 0}
                            paginationMode="client"
                            sortingMode="client"
                        />
                    </Widget>
                </Stack>
            )}
        </Page>
    );
};
